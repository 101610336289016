function docReady(fn) {
  // see if DOM is already available
  if (document.readyState === "complete" || document.readyState === "interactive") {
      // call on next available tick
      setTimeout(fn, 1);
  } else {
      document.addEventListener("DOMContentLoaded", fn);
  }
}


docReady(function() {

  document.getElementById('hamburger').addEventListener('click', function(e) {
    document.getElementsByTagName('body')[0].classList.toggle('-open-navigation');
  });




});
